import cn from 'classnames';
// Types
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
// Components
import StreamCard from 'components/common2/StreamCard/StreamCard';
import Section from 'components/common2/Section/Section';
import CarouselWithArrowsV2 from 'components/HomePage/components/common/CarouselWithArrowsV2/CarouselWithArrowsV2';
// Styles
import styles from './RelatedStreams.module.scss';

type RelatedStreamsProps = {
  streams:
    | GetAthleteStreams_liveAndUpcomingStreams_entities[]
    | GetAthleteStreams_pastStreams_entities[];
  className?: string;
};

const RelatedStreams = ({ streams, className }: RelatedStreamsProps) => {
  if (!streams?.length) {
    return null;
  }

  return (
    <Section title="" className={cn(styles.root, className)}>
      <p className={styles.title}>Other streams you might like:</p>

      <div className={styles.rootCarousel}>
        <CarouselWithArrowsV2
          settings={{ infinite: streams.length > 4 }}
          hideArrows={streams.length < 5}
        >
          {streams.map((stream) => (
            <StreamCard
              key={stream.id}
              stream={stream}
              showUpcomingLabel
              showPastLabel
            />
          ))}
        </CarouselWithArrowsV2>
      </div>
    </Section>
  );
};

export default RelatedStreams;
