import { useState } from 'react';
import { useQuery } from '@apollo/client';
// Api
import { GET_STREAM_PARTICIPANTS } from 'api/streams/queries';
// Types
import {
  GetStreamParticipants,
  GetStreamParticipantsVariables,
  GetStreamParticipants_getStreamParticipants_entities,
} from 'api/streams/types/GetStreamParticipants';
import {
  SortDirection,
  StreamParticipantsOrderBy,
} from 'api/graphql-global-types';
// Helpers
import { withCDN } from 'helpers/cdn';
// Ui
import Image from 'ui/Image/Image';
// Ui2
import LinkButton from 'ui2/LinkButton/LinkButton';
import Button from 'ui2/Button/Button';
// Components
import Section from 'components/common2/Section/Section';
// Styles
import styles from './StreamParticipants.module.scss';

type StreamParticipantsProps = {
  streamId: string;
};

const GET_STREAM_PARTICIPANTS_LIMIT = 10;

const StreamParticipants = ({ streamId }: StreamParticipantsProps) => {
  const [allParticipants, setAllParticipants] = useState<
    GetStreamParticipants_getStreamParticipants_entities[] | never[]
  >([]);

  const [pagination, setPagination] = useState({
    limit: GET_STREAM_PARTICIPANTS_LIMIT,
    offset: 0,
  });

  const { data, loading, fetchMore } = useQuery<
    GetStreamParticipants,
    GetStreamParticipantsVariables
  >(GET_STREAM_PARTICIPANTS, {
    variables: {
      input: {
        streamId,
        orderBy: StreamParticipantsOrderBy.firstName,
        direction: SortDirection.DESC,
        ...pagination,
      },
    },
    onCompleted: (newData: GetStreamParticipants) => {
      setAllParticipants((prevParticipants) => [
        ...prevParticipants,
        ...(newData?.getStreamParticipants?.entities || []),
      ]);
    },
    skip: !streamId,
    fetchPolicy: 'cache-and-network',
  });

  const handleLoadMore = () => {
    const newOffset = pagination.offset + pagination.limit;

    setPagination({
      ...pagination,
      offset: newOffset,
    });

    fetchMore({
      variables: {
        input: {
          streamId,
          direction: SortDirection.DESC,
          orderBy: StreamParticipantsOrderBy.firstName,
          ...pagination,
          offset: newOffset,
        },
      },
    });
  };

  const offset = data?.getStreamParticipants?.offset || 0;
  const total = data?.getStreamParticipants?.total || 0;

  if (allParticipants.length === 0) {
    return null;
  }

  return (
    <Section
      title="Athletes"
      backgroundTitle="Athletes"
      className={styles.section}
    >
      <div className={styles.root}>
        {allParticipants?.map(
          ({ id, firstName, lastName, storeDetails, slug }) => {
            const storeName = storeDetails?.storeName
              ? storeDetails.storeName
              : `${firstName} ${lastName}`;
            return (
              <div key={id} className={styles.logoAndTextWrapper}>
                <div className={styles.logoWrapper}>
                  <Image
                    className={styles.logoImage}
                    title={storeName}
                    alt={storeName}
                    src={
                      storeDetails?.avatarURL ||
                      withCDN('/images/logos/mobile-logo-black.svg')
                    }
                    sizes="10vw"
                  />
                </div>

                <div className={styles.infoWrapper}>
                  <p className={styles.storeName}>{storeName}</p>

                  <LinkButton l="small" color="harvest-gold" href={`/${slug}`}>
                    view profile
                  </LinkButton>
                </div>
              </div>
            );
          }
        )}
      </div>

      {total > GET_STREAM_PARTICIPANTS_LIMIT && (
        <Button
          type="button"
          loading={loading}
          className={styles.viewMoreBtn}
          onClick={handleLoadMore}
          disabled={offset > total}
        >
          view more athletes
        </Button>
      )}
    </Section>
  );
};

export default StreamParticipants;
