import { useQuery, QueryResult } from '@apollo/client';
// Api
import { GET_ATHLETE_STREAMS } from 'api/streams/queries';
// Types
import {
  StreamStatus,
  StreamOrderBy,
  SortDirection,
  StreamFilterInput,
} from 'api/graphql-global-types';
import {
  GetAthleteStreams,
  GetAthleteStreamsVariables,
} from 'api/streams/types/GetAthleteStreams';

type UseGetAthleteStreamsProps = {
  storeId: string | undefined;
  liveAndUpcomingStreamsInput: StreamFilterInput;
  pastStreamsInput: StreamFilterInput;
};

export const useGetAthleteStreams = ({
  storeId,
  liveAndUpcomingStreamsInput,
  pastStreamsInput,
}: UseGetAthleteStreamsProps): QueryResult<
  GetAthleteStreams,
  GetAthleteStreamsVariables
> => {
  return useQuery<GetAthleteStreams, GetAthleteStreamsVariables>(
    GET_ATHLETE_STREAMS,
    {
      variables: {
        liveAndUpcomingStreamsInput: {
          storeId,
          streamStatus: [
            StreamStatus.Active,
            StreamStatus.Paused,
            StreamStatus.Interrupted,
            StreamStatus.Scheduled,
          ],
          orderBy: StreamOrderBy.ScheduleDate,
          direction: SortDirection.ASC,
          limit: liveAndUpcomingStreamsInput?.limit || 10,
          offset: liveAndUpcomingStreamsInput?.offset || 0,
          ...liveAndUpcomingStreamsInput,
        },
        pastStreamsInput: {
          storeId,
          streamStatus: [StreamStatus.Ended],
          orderBy: StreamOrderBy.ScheduleDate,
          direction: SortDirection.ASC,
          limit: pastStreamsInput?.limit || 10,
          offset: pastStreamsInput?.offset || 0,
          ...pastStreamsInput,
        },
      },
      skip: !storeId,
    }
  );
};
