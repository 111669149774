import { FC } from 'react';
import Link from 'next/link';
import cn from 'classnames';
// Helpers
import { computeTagPath } from 'helpers/routes';
// Types
import { GetStoreBySlug_store_hashtags } from 'api/store/types/GetStoreBySlug';

import styles from './HashTags.module.scss';

type HashTagProps = {
  tags: GetStoreBySlug_store_hashtags[];
  className?: string;
  hashTagClassName?: string;
};

const HashTags: FC<HashTagProps> = ({ tags, className, hashTagClassName }) => {
  // Display a maximum first 5 tags
  const firstFiveTags = tags?.slice(0, 5);

  return (
    <ul className={cn(styles.tagsList, className)}>
      {firstFiveTags?.map((tag) => (
        <li key={tag.id} className={styles.tagsListItem}>
          <Link href={computeTagPath(tag)} prefetch={false}>
            <a className={cn(styles.hashTag, hashTagClassName)}>{tag.name}</a>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default HashTags;
