import dayjs from 'dayjs';
// Types
import {
  GetAthleteStreams_liveAndUpcomingStreams_entities,
  GetAthleteStreams_pastStreams_entities,
} from 'api/streams/types/GetAthleteStreams';
// Hooks
import { useGetAthleteStreams } from './useGetAthleteStreams';
import { useRefetchOnUserLogIn } from './useRefetchOnUserLogIn';

type UseAthleteRelatedStreamsProps = {
  storeId: string;
  currentStreamId?: string;
  totalStreamsToShow?: number;
};

type RelatedStreamsOutput = {
  streams:
    | GetAthleteStreams_liveAndUpcomingStreams_entities[]
    | GetAthleteStreams_pastStreams_entities[];
  total: number;
};

export const useAthleteRelatedStreams = ({
  storeId,
  currentStreamId,
  totalStreamsToShow = 5,
}: UseAthleteRelatedStreamsProps): RelatedStreamsOutput => {
  const { data, refetch } = useGetAthleteStreams({
    storeId,
    liveAndUpcomingStreamsInput: {
      scheduleDate: {
        from: dayjs().add(-1, 'day').startOf('day').toDate(), // taking -1 day because of long live streams
        to: dayjs().add(1, 'year').startOf('day').toDate(),
      },
      limit: 5,
    },
    pastStreamsInput: {
      limit: 5,
    },
  });

  const liveAndUpcomingStreamList =
    data?.liveAndUpcomingStreams?.entities ?? [];
  const pastStreamList = data?.pastStreams?.entities ?? [];
  const relatedStreams:
    | GetAthleteStreams_liveAndUpcomingStreams_entities[]
    | GetAthleteStreams_pastStreams_entities[] = [];

  liveAndUpcomingStreamList.forEach((stream) => {
    if (
      relatedStreams.length < totalStreamsToShow &&
      stream.id !== currentStreamId
    ) {
      relatedStreams.push(stream);
    }
  });

  pastStreamList.forEach((pastStream) => {
    if (
      relatedStreams.length < totalStreamsToShow &&
      pastStream.id !== currentStreamId
    ) {
      relatedStreams.push(pastStream);
    }
  });

  useRefetchOnUserLogIn({ refetch });

  return {
    streams: relatedStreams,
    total:
      (data?.liveAndUpcomingStreams?.total ?? 0) +
      (data?.pastStreams?.total ?? 0),
  };
};
