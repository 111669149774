import { useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
// Types
import { StreamStatus } from 'api/graphql-global-types';
import { GetAllHomePageData_getHomePageData_watchStreams } from 'api/homePage/types/GetAllHomePageData';
import { GetStreams_streamsV2_entities } from 'api/streams/types/GetStreams';
import { GetUpcomingStreams_streams_entities } from 'api/streams/types/GetUpcomingStreams';
// Hooks
import { useGetCurrUser, useBookFreeStream } from 'hooks';
// Helpers
import { getStreamerName, getPublicStreamDate } from 'helpers/streams';
import { formatCurrencyString } from 'helpers/formatCurrencyString';
import { computeWatchStreamPath, computeOrderStreamPath } from 'helpers/routes';
// Constants
import { ATHLETES } from 'constants/routes';
// Ui
import Image from 'ui/Image/Image';
// Ui2
import Button from 'ui2/Button/Button';
import Notification from 'ui2/Notification/Notification';

import styles from './StreamCard.module.scss';

type StreamCardProps = {
  stream:
    | GetUpcomingStreams_streams_entities
    | GetAllHomePageData_getHomePageData_watchStreams
    | GetStreams_streamsV2_entities;
  showUpcomingLabel?: boolean;
  showPastLabel?: boolean;
  className?: string;
};

const StreamCard = ({
  stream,
  showUpcomingLabel = false,
  showPastLabel = false,
  className,
}: StreamCardProps) => {
  const router = useRouter();
  const { data: userData } = useGetCurrUser();

  const bookFreeStream = useBookFreeStream();

  const [isSuccessModalVisible, setSuccessModalVisibility] = useState<boolean>(
    false
  );

  const streamName = stream.name || '';
  const storeName = getStreamerName(stream.store);
  const streamPrice = stream.price;
  const streamDate = getPublicStreamDate(stream.scheduleDate, stream.timeZone);
  const watchStreamPath = computeWatchStreamPath(stream);
  const streamDetailsPath = computeOrderStreamPath(stream);
  const isUsersStream = userData?.me?.id === stream.store?.id;
  const isPaid = stream.isPurchased || isUsersStream;
  const isLive = [
    StreamStatus.Active,
    StreamStatus.Paused,
    StreamStatus.Interrupted,
  ].includes(stream.streamStatus);
  const isEnded = stream.streamStatus === StreamStatus.Ended;
  const isUpcomming = stream.streamStatus === StreamStatus.Scheduled;
  const showRepeatLabel =
    !isLive && (stream as GetStreams_streamsV2_entities).repeatsEveryEnum;

  const handleStreamButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    bookFreeStream(stream);
  };

  const handleStoreButtonClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const url = stream.store?.slug ? `/${stream.store?.slug}` : ATHLETES;
    router.push(url);
  };

  const handleSuccessPaymentModalClose = () => {
    setSuccessModalVisibility(false);
    router.push(watchStreamPath);
  };

  const handleCloseModal = () => {
    setSuccessModalVisibility(false);
  };

  const buttonContent =
    !isPaid && stream.isFree
      ? 'Watch - Free'
      : isPaid
      ? 'Watch Stream'
      : `Order - ${formatCurrencyString(streamPrice)}`;

  return (
    <>
      <Link href={streamDetailsPath} prefetch={false}>
        <a
          className={cn(styles.root, className)}
          title={`"${streamName}" by ${storeName} at ${streamDate}`}
        >
          <div className={styles.imageWrapper}>
            <Image
              src={stream.imageURL || ''}
              alt={streamName}
              priority={false}
              placeholder="blur"
              sizes="(max-width: 768px) 50vw, (max-width: 991px) 33vw, 20vw"
            />
          </div>

          {isLive && (
            <div className={cn(styles.statusBadge, styles.liveNow)}>Live</div>
          )}
          {showUpcomingLabel && isUpcomming && !showRepeatLabel && (
            <div className={cn(styles.statusBadge, styles.upcoming)}>
              Upcoming
            </div>
          )}
          {showPastLabel && isEnded && (
            <div className={cn(styles.statusBadge, styles.ended)}>Past</div>
          )}
          {showRepeatLabel && (
            <div className={cn(styles.statusBadge, styles.repeatStream)}>
              {showRepeatLabel}
            </div>
          )}
          <button
            className={styles.streamerName}
            onClick={handleStoreButtonClick}
          >
            {storeName}
          </button>
          <div className={styles.infoWrapper}>
            <p className={styles.streamCardTitle}>{stream.name}</p>
            <p className={styles.daysDifference}>{streamDate}</p>
          </div>
          <Button
            xs="xSmall"
            color="transparent"
            onClick={handleStreamButtonClick}
            className={styles.watchButton}
          >
            {buttonContent}
          </Button>
        </a>
      </Link>

      <Notification
        open={isSuccessModalVisible}
        title="Thank you for registering"
        description={
          <>
            You&apos;ve successfully registered for{' '}
            <strong>{streamName} </strong>
            stream from <strong>{storeName}</strong>. You can join stream via
            the Purchase section of your account at any time. You will also be
            sent an email reminder 1 day before the event and 15 mins before it
            starts.
          </>
        }
        onCancel={handleCloseModal}
        okText="Go to stream page"
        cancelText="Continue shopping"
        onOk={handleSuccessPaymentModalClose}
      />
    </>
  );
};

export default StreamCard;
