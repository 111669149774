import { useRouter } from 'next/router';
import Link from 'next/link';
// Types
import { GetStream_stream } from 'api/streams/types/GetStream';
// Styles
import styles from './StreamNavigation.module.scss';

type StreamNavigationProps = {
  stream: GetStream_stream | undefined;
};

const StreamNavigation = ({ stream }: StreamNavigationProps) => {
  const { query } = useRouter();

  const athleteSlug = query.athleteSlug as string;

  const mediaFeedUrl = () => {
    return (
      <div className={styles.link}>
        <Link href={`/${athleteSlug}/streams?athleteIds=${stream?.store.id}`}>
          STREAMS
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      {mediaFeedUrl()}
      &#62;
      <div className={styles.text}>{stream?.name}</div>
    </div>
  );
};

export default StreamNavigation;
