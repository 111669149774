import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// Constants
import { STRIPE_PUBLISHABLE_KEY } from 'constants/stripe';

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StripeElements: React.FC = ({ children }) => {
  return <Elements stripe={stripe}>{children}</Elements>;
};

export default StripeElements;
